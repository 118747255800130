import React from 'react';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { replaceAll} from '../../utils/helpers'
import '../../sass/global/styles.scss';
import FontUrl1 from "../../fonts/webFonts/ConduitITCPro-Bold/font.woff2"

const Layout = ({ children, location, wordpressUrl, includeCss = false }) => {
  const { pathname } = location
  const className =  pathname === '/' ? 'home' : pathname.slice(1, -1).replaceAll('/',' ')
  return (
    <div id="layout" className={className}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="anonymous" />
        <link rel="preload" as="font" href={FontUrl1} type="font/woff2" crossOrigin="anonymous" />
      </Helmet>
      <Header location={location} />
      <div className="main">{children}</div>
      <Footer location={location} />
    </div>
  )
}

export default Layout

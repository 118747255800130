import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/Logo'
import HeaderNav from '../HeaderNav';
import Headroom from 'react-headroom';
import { SocialLinks } from '../SocialLinks'
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import './Header.scss';
import { decodeEntities } from '../../utils/helpers';
const isClient = typeof window !== 'undefined';

class Header extends Component {
  state = {
    searchActive: false,
    contactActive: false,
    navActive: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if(isClient) {
        let childrenDomElements = document.querySelectorAll('.children');
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav();
        }
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSearch = event => {
    const { searchActive } = this.state;
    if (event) event.preventDefault();
    if (!searchActive) {
      this.closeAll();
      this.setState({ searchActive: true });
    } else {
      this.setState({ searchActive: false });
    }
  };

  toggleNav = event => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add('nav-open');
      }
      this.setState({
        contactActive: false,
        navActive: true,
        searchActive: false,
      });
    } else {
      if (isClient) {
        document.body.classList.remove('nav-open');
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = event => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
    this.setState({
      searchActive: false,
      navActive: false,
    });
  };

  render() {
    const {
      searchActive,
      navActive,
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      abovePrimaryNavigation,
      primaryNavigation,
      siteSettings,
      metaData,
      wordpressWpSettings
    } = data;
    const { options } = siteSettings
    const { locations, invertHeaderColours = null, contactNumberHeader, quote, showQuoteButtonInHeader, quotePage, socialLinksHeader } = options
    const { phone, email, addressDisplay  } = locations[0];
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation
    const { items: aboveNavItems } = abovePrimaryNavigation
    return (
      <Headroom disableInlineStyles={true} pinStart={50}>
      <header className={invertHeaderColours ? 'invert' : ''}>

        <div className="header-main">
          <div className="wrap">
            <div className="inner">
              <Logo />

              <div className="above-header">
                <div className="inner">

                  <HeaderNav active={navActive} className="above-head-navigation" navItems={aboveNavItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />

                  <div className="contacts">
                    {email &&
                      <div className="email"><a aria-label="Email" href={`mailto:${decodeEntities(email)}`}><FaEnvelope /> Email</a></div>
                    }

                    {contactNumberHeader &&
                      <div className="phone"><a
                      href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                      className='control-call'
                      aria-label="Call Now"><FaPhone />{phone}</a></div>
                    }
                  </div>
                </div>

              </div>

              <div className="controls">
                <button
                  type="button"
                  className={`control-nav icon-hamburger ${navActive ? 'active' : ''}`}
                  onClick={this.toggleNav}
                  aria-label="Toggle Menu"
                ><span className={`burger ${navActive ? 'active' : ''}`} /></button>
              </div>

              <div className={`nav${navActive ? ' active' : ''}`}>
                <div className="nav-wrap">
                  <div className={`nav-inner`}>

                    <HeaderNav active={navActive} className="nav-list" navItems={navItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />

                    <div className="contact-info">
                      <div className="address">
                        <span className="h5 section-title">Address:</span>
                        <p dangerouslySetInnerHTML={{__html: addressDisplay.split(', ').join('</br>')}} />
                      </div>

                      <div className="contact">
                        <span className="h2">Let's talk about how we can help you stand out.</span>
                        <p><a aria-label="Email" href={`mailto:${decodeEntities(email)}`} dangerouslySetInnerHTML={{__html: email}} />
                        <br/><a
                        href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                        className='control-call'
                        aria-label="Call Now">{phone}</a></p>
                      </div>

                      <SocialLinks/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      </Headroom>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "above-primary-navigation" }) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
              children: wordpress_children {
                title
                url
                classes
                object_id
                slug: object_slug
                target
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            navigationAbovePrimaryMenu
            socialLinksHeader
            contactNumberHeader
            invertHeaderColours
            showSearchIconInMainNavigation
            locations {
              addressDisplay
              email
              phone
            }
            quote
            quotePage
            showQuoteButtonInHeader
            quotePage
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)

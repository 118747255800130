import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/LogoFooter';
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import { SocialLinks } from '../SocialLinks'
import './Footer.scss';
import { decodeEntities } from '../../utils/helpers';

class Footer extends Component {
  render() {
    const { data, location = {} } = this.props;
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      wordpressWpSettings
    } = data;
    const { items: legalNav } = legalNavigation;
    const { items: footerNav } = footerNavigation
    const { footerTagline, navigationInFooter, contactDetailsInFooter, privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = siteSettings.options
    const {
      email,
      phone,
      addressDisplay
    } = siteSettings.options.locations[0];
    const currentYear = (new Date()).getFullYear();

    return (
      <>
      <footer>
        <div className="footer-main">

          <div className="wrap">

            <div className="inner">

              <div className="one">
                <div className="contact-text">
                  <span className="h2">Let’s talk about how<br/>we can help you stand out.</span>

                  {email &&
                    <div>
                      <a href={`mailto:${decodeEntities(email)}`} className="control-email"><span dangerouslySetInnerHTML={{__html: email}} /></a>
                    </div>
                  }
                  {phone && <div><a
                    href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                    className='control-call'
                    aria-label="Call Now"
                  >{phone}</a></div>}
                </div>

                {siteSettings.options.socialLinksFooter &&
                  <SocialLinks/>
                 }
              </div>

              <div className="two">

                <div className="address">
                    <span className="title">Address:</span>
                    <GatsbyLink to="https://maps.google.com/maps?cid=2420555980210680838"><span dangerouslySetInnerHTML={{__html: `${addressDisplay.split(', ').join('</br>')}`}} /></GatsbyLink>
                </div>

                {navigationInFooter &&
                  <ul className="footer-nav">
                  {footerNav  && footerNav.map((item, index) => (
                    <li key={index}><GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink></li>
                  ))
                  }
                  </ul>
                }

                <div className="logos">
                  <Logo />
                  {footerTagline && <RenderContent className="tagline" content={footerTagline}/>}
                </div>

              </div>

              <div className="three">

                <div className="copyright">
                  <span>©{currentYear} <strong>{wordpressWpSettings.title}</strong>, All rights reserved</span>
                </div>

                {legalNav &&
                  <div className="navigation">
                    <ul>
                      {legalNav.map((item, index) => (
                        <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                      ))}
                      <li>
                      {location.pathname === "/" ? (
                        <span className="distl">
                          Website by{" "}
                          <a rel="noreferrer" target="_blank" href="https://distl.com.au/">
                            Distl
                          </a>
                        </span>
                      ) : (
                        <span className="distl">Website by Distl</span>
                      )}
                      </li>
                    </ul>
                  </div>
                }
  

              </div>
            </div>
          </div>
        </div>
      </footer>
      </>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        },
        footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        },
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
